
import DisplayStory from "@evercam/shared/components/DisplayStory"
import { useCameraStore } from "@/stores/camera"
import { AnalyticsEvent, InfoPage } from "@evercam/shared/types"

export default {
  components: {
    DisplayStory,
  },
  data() {
    return {
      pageName: "",
    }
  },
  head() {
    return {
      title: `${useCameraStore().selectedCamera?.name || "Evercam"} | ${
        this.pageName
      }`,
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    storySlug() {
      return `info-pages/${this.$route.params.page}`
    },
  },
  methods: {
    updateStory({ name }) {
      this.pageName = name

      const pageInfoEventIdsMap = {
        [InfoPage.BIMView]: AnalyticsEvent.infoBim,
        [InfoPage.DroneView]: AnalyticsEvent.infoDrone,
        [InfoPage._360View]: AnalyticsEvent.info360,
        [InfoPage.GateReport]: AnalyticsEvent.infoGateReport,
        [InfoPage.BimCompare]: AnalyticsEvent.infoBimCompare,
      }
      this.$analytics.saveEvent(pageInfoEventIdsMap[this.$route.params.page])
    },
  },
}
